import React from 'react';
import {observer} from "mobx-react";
import {Button, Card, Select, Input} from 'antd';
import {SearchForm} from '../../components';
import GoodsTable from './Table';
import store from './store';

@observer
class GoodsIndex extends React.Component {
  render() {
    return (
      <Card>
        <SearchForm>
          <SearchForm.Item span={8} title="拼团状态">
            <Select allowClear placeholder="请选择" value={store.f_status} onChange={v => store.f_status = v}>
              <Select.Option value="blue">未上架</Select.Option>
              <Select.Option value="green">进行中</Select.Option>
              <Select.Option value="orange">已结束</Select.Option>
              <Select.Option value="red">已下架</Select.Option>
            </Select>
          </SearchForm.Item>
          <SearchForm.Item span={8} title="商品信息">
            <Input allowClear placeholder="请输入" value={store.f_name} onChange={e => store.f_name = e.target.value}/>
          </SearchForm.Item>
          <SearchForm.Item span={6}>
            <Button icon="sync" type="primary" onClick={() => store.fetchRecords()}>刷新</Button>
          </SearchForm.Item>
        </SearchForm>
        <div style={{marginBottom: 16}}>
          <Button type="primary" icon="plus" onClick={() => store.showForm()}>新建</Button>
        </div>
        <GoodsTable/>
      </Card>
    )
  }
}

export default GoodsIndex