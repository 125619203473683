import React from 'react';
import {Modal, Form, Select, message} from 'antd';
import http from '../../libs/http';
import store from './store';

class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData.id = store.record.id;
    http.post('/api/order/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={700}
        maskClosable={false}
        title='编辑订单'
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} label="用户昵称">
            {info['user_nickname']}
          </Form.Item>
          <Form.Item {...itemLayout} label="商品名称">
            {info['goods_title']}
          </Form.Item>
          <Form.Item {...itemLayout} label="订单状态">
            {getFieldDecorator('status', {initialValue: info['status']})(
              <Select>
                <Select.Option value="0">待确认</Select.Option>
                <Select.Option value="1">已支付</Select.Option>
                <Select.Option value="2">已完成</Select.Option>
                <Select.Option value="-1">待付款</Select.Option>
                <Select.Option value="-2">已退款</Select.Option>
                <Select.Option value="-3">已取消</Select.Option>
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(OrderForm)