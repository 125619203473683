import React from 'react';
import {observer} from 'mobx-react';
import {Modal, Form, Input, Select, message} from 'antd';
import http from '../../../libs/http';
import storeStore from '../../store/store';
import store from './store';
import lds from 'lodash';

@observer
class AccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    if (lds.isEmpty(storeStore.records)) {
      storeStore.fetchRecords()
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    http.post('/api/account/user/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        maskClosable={false}
        title={store.record.id ? '编辑账户' : '新建账户'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="登录名">
            {getFieldDecorator('username', {initialValue: info['username']})(
              <Input placeholder="请输入登录名"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="姓名">
            {getFieldDecorator('nickname', {initialValue: info['nickname']})(
              <Input placeholder="请输入姓名"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="密码">
            {getFieldDecorator('password', {initialValue: info.id ? '******' : undefined})(
              <Input type="password" placeholder="请输入密码"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="门店">
            {getFieldDecorator('store_id', {initialValue: info['store_id'] || undefined})(
              <Select placeholder="请选择所属门店">
                {storeStore.records.map(item => (
                  <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(AccountForm)