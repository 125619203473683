import React from 'react';
import { observer } from "mobx-react";
import { Button, Card, Input, Select } from 'antd';
import { SearchForm, StatisticsCard } from '../../components';
import UserTable from './Table';
import store from './store';
import http from '../../libs/http';
import { exportToExcel, human_date } from '../../libs/functools';

@observer
class UserIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false
    }
  }

  componentDidMount() {
    store.fetchAnalysis();
    store.fetchStores();
  }

  handleExport = () => {
    this.setState({isExporting: true});
    http.get('/api/wx/export/register/', {responseType: 'blob'})
      .then(res => {
        exportToExcel(res.data, `截止${human_date()}未注册会员.xlsx`);
        this.setState({isExporting: false})
      })
  };

  render() {
    return (
      <React.Fragment>
        <StatisticsCard loading={store.topFetching}>
          <StatisticsCard.Item title="用户总数" value={store.analysis['total']}/>
          <StatisticsCard.Item title="今日新增" value={store.analysis['today']}/>
          <StatisticsCard.Item title="昨天新增" value={store.analysis['yesterday']}/>
        </StatisticsCard>
        <Card>
          <SearchForm>
            <SearchForm.Item span={5} title="用户昵称">
              <Input onChange={e => store.f_name = e.target.value} placeholder="请输入"/>
            </SearchForm.Item>
            <SearchForm.Item span={5} title="用户状态">
              <Select allowClear placeholder="请选择" value={store.f_active} onChange={v => store.f_active = v}>
                <Select.Option value="true">启用</Select.Option>
                <Select.Option value="false">禁用</Select.Option>
              </Select>
            </SearchForm.Item>
            <SearchForm.Item span={5} title="用户角色">
              <Select allowClear placeholder="请选择" value={store.f_role} onChange={v => store.f_role = v}>
                <Select.Option value="0">顾客</Select.Option>
                <Select.Option value="1">店员</Select.Option>
                <Select.Option value="2">店长</Select.Option>
                <Select.Option value="3">会计</Select.Option>
                <Select.Option value="4">出纳</Select.Option>
                <Select.Option value="5">巡店督导</Select.Option>
                <Select.Option value="10">管理员</Select.Option>
              </Select>
            </SearchForm.Item>
            <SearchForm.Item span={4}>
              <Button icon="sync" type="primary" onClick={() => store.fetchRecords()}>刷新</Button>
            </SearchForm.Item>
            <SearchForm.Item span={4}>
              <Button
                icon="export"
                type="primary"
                loading={this.state.isExporting}
                onClick={this.handleExport}>导出</Button>
            </SearchForm.Item>
          </SearchForm>
          <UserTable/>
        </Card>
      </React.Fragment>
    )
  }
}

export default UserIndex