import React from 'react';
import {Modal, Form, Input, message} from 'antd';
import http from '../../libs/http';
import store from './store';

class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData.id = store.record.id;
    formData.parent_id = store.record.parent_id;
    http.post('/api/notify/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    return (
      <Modal
        visible
        width={700}
        maskClosable={false}
        title={store.record.id ? '编辑菜单' : '新建菜单'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form labelCol={{span: 6}} wrapperCol={{span: 14}}>
          <Form.Item required label="菜单标题">
            {getFieldDecorator('name', {initialValue: info['name']})(
              <Input placeholder="请输入菜单标题"/>
            )}
          </Form.Item>
          <Form.Item label="菜单描述">
            {getFieldDecorator('desc', {initialValue: info['desc']})(
              <Input placeholder="请输入菜单描述"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)