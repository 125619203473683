import {observable} from "mobx";
import http from '../../libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable isFetching = false;
  @observable formVisible = false;
  @observable articleVisible = false;

  @observable f_status;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/notify/')
      .then(res => this.records = res)
      .finally(() => this.isFetching = false)
  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info
  };

  showSubForm = (info) => {
    this.record = {parent_id: info.id};
    this.formVisible = true
  };

  showArticle = (info) => {
    this.record = info;
    this.articleVisible = true
  }
}

export default new Store()