import React from 'react';
import {Modal, Form, Input, InputNumber, message} from 'antd';
import http from '../../libs/http';
import store from './store';

class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData.id = store.record.id;
    formData.store_id = store.store_id;
    http.post('/api/inspect/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    return (
      <Modal
        visible
        width={700}
        maskClosable={false}
        title={store.record.id ? '编辑检查区域' : '新建检查区域'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form labelCol={{span: 6}} wrapperCol={{span: 14}}>
          <Form.Item required label="检查区域">
            {getFieldDecorator('name', {initialValue: info['name']})(
              <Input placeholder="请输入菜单标题"/>
            )}
          </Form.Item>
          <Form.Item required label="照片限制">
            {getFieldDecorator('limit', {initialValue: info['limit']})(
              <InputNumber style={{width: '100%'}} placeholder="请输入该区域最少需要上传的照片数量"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ComForm)