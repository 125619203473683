import React from 'react';
import { Modal, Form, Input, Switch, Select, message } from 'antd';
import http from '../../libs/http';
import store from './store';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData.id = store.record.id;
    http.post('/api/wx/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={700}
        maskClosable={false}
        title={store.record.id ? '编辑账户' : '新建账户'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} label="用户头像">
            <img style={{width: 30, height: 30}} src={info['avatar']} alt="avatar"/>
          </Form.Item>
          <Form.Item {...itemLayout} required label="用户昵称">
            {getFieldDecorator('nickname', {initialValue: info['nickname']})(
              <Input placeholder="请输入用户昵称"/>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} label="允许登录">
            {getFieldDecorator('is_active', {initialValue: info['is_active'], valuePropName: 'checked'})(
              <Switch/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="用户角色">
            {getFieldDecorator('role', {initialValue: info['role']})(
              <Select placeholder="请选择">
                <Select.Option value="0">顾客</Select.Option>
                <Select.Option value="1">店员</Select.Option>
                <Select.Option value="2">店长</Select.Option>
                <Select.Option value="3">会计</Select.Option>
                <Select.Option value="4">出纳</Select.Option>
                <Select.Option value="5">巡店督导</Select.Option>
                <Select.Option value="10">管理员</Select.Option>
              </Select>
            )}
          </Form.Item>
          {['1', '2'].includes(getFieldValue('role')) && (
            <Form.Item {...itemLayout} label="所属门店">
              {getFieldDecorator('store_id', {initialValue: info['store_id']})(
                <Select placeholder="请选择">
                  {store.stores.map(item => (
                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(UserForm)