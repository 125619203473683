import React from 'react';
import {Modal, Form, Input, InputNumber, DatePicker, message} from 'antd';
import {Upload} from "../../components";
import http from '../../libs/http';
import store from './store';
import moment from 'moment';

class GoodsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData.expired_at = formData.expired_at.format('YYYY-MM-DD HH:mm:ss');
    formData.id = store.record.id;
    http.post('/api/goods/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={700}
        maskClosable={false}
        title={store.record.id ? '编辑账户' : '新建账户'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="排序编号">
            {getFieldDecorator('order_id', {initialValue: info['order_id']})(
              <InputNumber style={{width: '100%'}} placeholder="影响展示的排序，数字越小越靠前"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品标题">
            {getFieldDecorator('title', {initialValue: info['title']})(
              <Input placeholder="请输入商品标题"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品规格">
            {getFieldDecorator('spec', {initialValue: info['spec']})(
              <Input placeholder="请输入商品规格"/>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} required label="拼团人数">
            {getFieldDecorator('scale', {initialValue: info['scale']})(
              <Input placeholder="请输入拼团人数"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="已售基数">
            {getFieldDecorator('sales', {initialValue: info['sales']})(
              <InputNumber style={{width: '100%'}} placeholder="请输入已售基数"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品市价">
            {getFieldDecorator('market_price', {initialValue: info['market_price']})(
              <Input placeholder="请输入商品市价"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品团价">
            {getFieldDecorator('group_price', {initialValue: info['group_price']})(
              <Input placeholder="请输入商品团价"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="限购数量">
            {getFieldDecorator('limit', {initialValue: info['limit']})(
              <Input placeholder="请设置限购数量0为不限制"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="结束时间">
            {getFieldDecorator('expired_at', {initialValue: moment(info['expired_at'])})(
              <DatePicker showTime placeholder="请设置团购结束时间"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="提货日期">
            {getFieldDecorator('pick_time', {initialValue: info['pick_time']})(
              <Input placeholder="请输入提货日期"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品封面图">
            {getFieldDecorator('cover_url', {initialValue: info['cover_url']})(
              <Upload maxCount={1} path="bait"/>
            )}
            <span style={{color: '#888', fontSize: 12}}>720px * 350px</span>
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品缩略图">
            {getFieldDecorator('thumb_url', {initialValue: info['thumb_url']})(
              <Upload maxCount={1} path="bait"/>
            )}
            <span style={{color: '#888', fontSize: 12}}>200px * 200px</span>
          </Form.Item>
          <Form.Item {...itemLayout} required label="商品详情图">
            {getFieldDecorator('detail_urls', {initialValue: info['detail_urls']})(
              <Upload maxCount={5} path="bait"/>
            )}
            <span style={{color: '#888', fontSize: 12}}>750px * 500px</span>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(GoodsForm)