import React from 'react';
import {observer} from "mobx-react";
import {Button, Card, Input, Select, DatePicker} from 'antd';
import {SearchForm} from '../../components';
import UserTable from './Table';
import store from './store';
import storeStore from "../store/store";

@observer
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false
    }
  }

  componentDidMount() {
    if (storeStore.records.length === 0) {
      storeStore.fetchRecords()
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <SearchForm>
            <SearchForm.Item span={6} title="优惠券名称">
              <Input onChange={e => store.f_name = e.target.value} placeholder="请输入"/>
            </SearchForm.Item>
            <SearchForm.Item span={6} title="门店">
              <Select allowClear placeholder="请选择" value={store.f_store_id} onChange={v => store.f_store_id = v}>
                {storeStore.records.map(item => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </SearchForm.Item>
            <SearchForm.Item span={6} title="日期">
              <DatePicker allowClear placeholder="请选择" value={store.f_date} onChange={v => store.f_date = v} />
            </SearchForm.Item>
            <SearchForm.Item span={4}>
              <Button icon="sync" type="primary" onClick={() => store.fetchRecords()}>刷新</Button>
            </SearchForm.Item>
          </SearchForm>
          <div style={{marginBottom: 16}}>
            <Button type="primary" icon="plus" onClick={() => store.showForm()}>新建</Button>
          </div>
          <UserTable/>
        </Card>
      </React.Fragment>
    )
  }
}

export default Index