import React from 'react';
import { observer } from "mobx-react";
import { Button, Card, Select, DatePicker } from 'antd';
import { SearchForm, StatisticsCard } from '../../components';
import { exportToExcel, human_time } from '../../libs/functools'
import OrderTable from './Table';
import storeStore from '../store/store';
import store from './store';
import http from '../../libs/http';

@observer
class OrderIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false
    }
  }

  componentDidMount() {
    store.fetchAnalysis();
    if (storeStore.records.length === 0) {
      storeStore.fetchRecords()
    }
  }

  doExport = () => {
    this.setState({isExporting: true});
    const formData = {};
    if (store.f_date) formData['date'] = store.f_date.format('YYYY-MM-DD');
    if (store.f_status) formData['status'] = store.f_status;
    if (store.f_store_id) formData['store_id'] = store.f_store_id;
    http.post('/api/order/export/', formData, {responseType: 'blob'})
      .then(res => exportToExcel(res.data, `${human_time()}订单信息.xlsx`))
      .finally(() => this.setState({isExporting: false}))
  };

  render() {
    return (
      <React.Fragment>
        <StatisticsCard loading={store.topFetching}>
          <StatisticsCard.Item title="今日总订单" value={store.analysis['total']}/>
          <StatisticsCard.Item title="已支付订单" value={store.analysis['pay']}/>
          <StatisticsCard.Item title="今日销售额" value={store.analysis['sales']}/>
        </StatisticsCard>
        <Card>
          <SearchForm>
            <SearchForm.Item span={6} title="起始时间">
              <DatePicker value={store.f_date} onChange={v => store.f_date = v} placeholder="请选择"/>
            </SearchForm.Item>
            <SearchForm.Item span={5} title="订单状态">
              <Select allowClear placeholder="请选择" value={store.f_status} onChange={v => store.f_status = v}>
                <Select.Option value="1">已支付</Select.Option>
                <Select.Option value="2">已完成</Select.Option>
                <Select.Option value="0">待确认</Select.Option>
                <Select.Option value="-1">待付款</Select.Option>
                <Select.Option value="-2">已退款</Select.Option>
                <Select.Option value="-3">已关闭</Select.Option>
              </Select>
            </SearchForm.Item>
            <SearchForm.Item span={7} title="提货门店">
              <Select allowClear placeholder="请选择" value={store.f_store_id} onChange={v => store.f_store_id = v}>
                {storeStore.records.map(item => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </SearchForm.Item>
            <SearchForm.Item span={3}>
              <Button icon="sync" type="primary" onClick={() => store.fetchRecords()}>刷新</Button>
            </SearchForm.Item>
            <SearchForm.Item span={3} style={{textAlign: 'right'}}>
              <Button
                icon="export"
                type="primary"
                loading={this.state.isExporting}
                style={{backgroundColor: 'orange', borderColor: 'orange'}}
                onClick={this.doExport}>导出</Button>
            </SearchForm.Item>
          </SearchForm>
          <OrderTable/>
        </Card>
      </React.Fragment>
    )
  }
}

export default OrderIndex