import React from 'react';
import {observer} from 'mobx-react';
import {Table, Tag} from 'antd';
import OrdeerForm from './Form';
import store from './store';

@observer
class OrderTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  color_map = {
    '0': 'orange',
      '1': 'blue',
    '2': 'green',
    '-1': 'volcano',
    '-2': 'red',
    '-3': 'magenta'
  };

  columns = [{
    title: '用户信息',
    key: 'user',
    render: info => (
      <span>
        <b>头像</b>: <img style={{width: 20, height: 20}} src={info['user_avatar']} alt="avatar" />
        <br/>
        <b>昵称</b>: {info['user_nickname']}
      </span>
    )
  }, {
    title: '商品信息',
    key: 'gp',
    render: info => (
      <span>
        <b>标题</b>: {info['goods_title']}
        <br/>
        <b>规格</b>: {info['goods_spec']}
      </span>
    )
  }, {
    title: '订单信息',
    key: 'pt',
    render: info => (
      <span>
        <b>数量</b>: {info['amount']}
        <br/>
        <b>总价</b>: ¥ {info['total_price']}
      </span>
    )
  }, {
    title: '提货信息',
    key: 'hd',
    render: info => (
      <span>
        <b>门店</b>: {info['store_name']}
        <br/>
        <b>时间</b>: {info['pick_time']}
      </span>
    )
  }, {
    title: '订单状态',
    key: 'status',
    render: info => <Tag color={this.color_map[info['status']]}>{info['status_alias']}</Tag>
  }, {
    title: '操作',
    key: 'cz',
    render: info => (
      <span>
        <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
      </span>
    )
  }];

  render() {
    let data = store.records;
    if (store.f_status) {
      data = data.filter(x => x['status'] === store.f_status)
    }
    if (store.f_store_id) {
      data = data.filter(x => x['store_id'] === store.f_store_id)
    }
    if (store.f_date) {
      const f_date = store.f_date.format('YYYY-MM-DD');
      data = data.filter(x => x['created_at'] >= f_date)
    }
    const pagination = {
      total: data.length,
      showTotal: t => `共 ${t} 单`
    };

    return (
      <React.Fragment>
        <Table loading={store.isFetching} rowKey="id" pagination={pagination} columns={this.columns} dataSource={data}/>
        {store.formVisible && <OrdeerForm/>}
      </React.Fragment>
    )
  }
}

export default OrderTable