import { makeModuleRoute } from "./libs/router";

import homeRoutes from './pages/home/routes';
import goodsRoutes from './pages/goods/routes';
import systemRoutes from './pages/system/routes';
import storeRoutes from './pages/store/routes';
import orderRoutes from './pages/order/routes';
import userRoutes from './pages/user/routes';
import billRoutes from './pages/bill/routes';
import settleRoutes from './pages/settle/routes';
import couponRoutes from './pages/coupon/routes';
import notifyRoutes from './pages/notify/routes';
import inspectRoutes from './pages/inspect/routes';


export default [
  makeModuleRoute('/home', homeRoutes),
  makeModuleRoute('/goods', goodsRoutes),
  makeModuleRoute('/store', storeRoutes),
  makeModuleRoute('/order', orderRoutes),
  makeModuleRoute('/user', userRoutes),
  makeModuleRoute('/system', systemRoutes),
  makeModuleRoute('/bill', billRoutes),
  makeModuleRoute('/settle', settleRoutes),
  makeModuleRoute('/coupon', couponRoutes),
  makeModuleRoute('/notify', notifyRoutes),
  makeModuleRoute('/inspect', inspectRoutes),
]