export default [
  {icon: 'desktop', title: '工作台', path: '/home'},
  {icon: 'appstore', auth: 'admin', title: '商品管理', path: '/goods'},
  {icon: 'red-envelope', auth: 'admin', title: '优惠券管理', path: '/coupon'},
  {icon: 'shop', auth: 'admin', title: '门店管理', path: '/store'},
  {icon: 'money-collect', auth: 'admin', title: '账单管理', path: '/bill/index'},
  {icon: 'pay-circle', auth: 'admin', title: '结账管理', path: '/settle'},
  {icon: 'shopping', auth: 'admin', title: '订单管理', path: '/order'},
  {icon: 'usergroup-add', auth: 'admin', title: '用户管理', path: '/user'},
  {icon: 'notification', auth: 'admin', title: '文化制度', path: '/notify'},
  {icon: 'monitor', auth: 'admin', title: '巡店管理', path: '/inspect'},
  {
    icon: 'setting', auth: 'admin', title: '系统管理', child: [
      {title: '账户管理', path: '/system/account'},
      {title: '系统信息', path: '/system/info'},
    ]
  },
]