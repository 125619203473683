import {observable} from "mobx";
import http from '../../libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable analysis = {};
  @observable isFetching = false;
  @observable formVisible = false;
  @observable topFetching = false;

  @observable f_status;
  @observable f_store_id;
  @observable f_date;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/order/')
      .then(res => this.records = res)
      .finally(() => this.isFetching = false)
  };

  fetchAnalysis = () => {
    this.topFetching = true;
    http.get('/api/order/analysis/')
      .then(res => this.analysis = res)
      .finally(() => this.topFetching = false)
  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info
  }
}

export default new Store()