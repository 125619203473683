import React from 'react';
import { Card } from 'antd';


export default class extends React.Component {
  render() {
    return (
      <Card>
        <span>The home page</span>
      </Card>
    )
  }
}