import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'antd';
import store from './store';

@observer
class StoreTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '门店名称',
    dataIndex: 'store_name'
  }, {
    title: '店长',
    dataIndex: 'user_nickname',
  }, {
    title: '打款金额',
    dataIndex: 'real_pay',
  }, {
    title: '提交时间',
    dataIndex: 'owner_date'
  }, {
    title: '审核时间',
    dataIndex: 'accounting_date'
  }, {
    title: '打款时间',
    dataIndex: 'cashier_date'
  }];

  render() {
    let data = store.records;
    if (store.f_status) {
      data = data.filter(x => x['status'] === store.f_status)
    }

    return (
      <React.Fragment>
        <Table loading={store.isFetching} rowKey="id" columns={this.columns} dataSource={data}/>
      </React.Fragment>
    )
  }
}

export default StoreTable