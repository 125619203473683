import {observable} from "mobx";
import http from '../../libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable isFetching = false;
  @observable formVisible = false;

  @observable f_name;
  @observable f_date;
  @observable f_store_id;

  fetchRecords = () => {
    this.isFetching = true;
    const date = this.f_date ? this.f_date.format('YYYY-MM-DD') : undefined;
    http.get('/api/coupon/', {params: {date, store_id: this.f_store_id}})
      .then(res => this.records = res)
      .finally(() => this.isFetching = false)
  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info
  }
}

export default new Store()