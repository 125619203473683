import React from 'react';
import { Modal, Upload as AUpload, Icon } from 'antd';
import { signOSSLink } from '../libs/functools';

// 使用说明：
// 支持的props：
//    path <string> OSS上传的前缀路径(!!!如不设置默认上传至oss根目录)
//    maxCount <number> 允许上传的最大数量
//    listType ['picture-card'|'picture'|'text']  同原始Upload组件的listType
//    value <string/array> 默认值，支持字符串或者数组
//    private <bool> OSS是否为私有，默认false，如果为私有时自动结合服务端中间件进行签名访问
//    onChange <event>  变动事件
//
// 结合getFieldDecorator使用例子:
// <Form.Item {...itemLayout} required label="商品封面图">
//    {getFieldDecorator('cover_url', {initialValue: info['cover_url']})(
//        <Upload maxCount={1} path="bait"/>
//    )}
// </Form.Item>
class Upload extends React.Component {
  static defaultProps = {
    path: '',
    maxCount: 1,
    listType: 'picture-card',
    private: false,
    onChange: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: [],
    }
  }

  componentDidMount() {
    let defaultValue = [];
    if (this.props.value) {
      defaultValue = typeof this.props.value === 'string' ? [this.props.value] : this.props.value;
    }
    const fileList = defaultValue.map((item, index) => {
      return ({
        uid: `-${index + 1}`,
        url: this.props.private ? signOSSLink(item) : item,
        path: item.replace(/^https?:\/\/[^/]+\/+/, '')
      })
    });
    if (fileList.length) {
      this.doChange(fileList)
    }
  }

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleChange = ({file, fileList}) => {
    fileList.filter(file => {
      if (file.response) {
        file.status = 'error';
        if (file.response.message) {
          file.response = file.response.message;
        } else if (file.response.data) {
          const {domain, path} = file.response.data;
          file.path = path;
          file.status = 'success';
          file.url = this.props.private ? signOSSLink(file.path) : domain + path;
        } else {
          file.response = '请求出错'
        }
      }
      return true
    });
    this.doChange(fileList)
  };

  doChange = (fileList) => {
    this.setState({fileList});
    if (this.props.maxCount === 1) {
      this.props.onChange(fileList.length ? fileList[0].path : undefined)
    } else {
      this.props.onChange(fileList.filter(item => item.path).map(item => item.path))
    }
  };

  render() {
    const {previewVisible, previewImage, fileList} = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus"/>
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div>
        <AUpload
          accept="image/*"
          action="/api/oss/upload/"
          disabled={this.props.disabled}
          listType={this.props.listType}
          showUploadList={{showPreviewIcon: true, showRemoveIcon: !this.props.path === false}}
          headers={{'X-Token': localStorage.getItem('token')}}
          data={{path: this.props.path, compress: 1}}
          fileList={this.state.fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {((this.props.maxCount && fileList.length >= this.props.maxCount) || !this.props.path) ? null : uploadButton}
        </AUpload>
        <Modal visible={previewVisible} footer={null} onCancel={() => this.setState({previewVisible: false})}>
          <img alt="example" style={{width: '100%'}} src={previewImage}/>
        </Modal>
      </div>
    );
  }
}

export default Upload