import React from 'react';
import {Form, Input, Icon, Button} from "antd";
import styles from './login.module.css';
import history from '../../libs/history';
import http from '../../libs/http';

class LoginIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }
  handleSubmit = () => {
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.setState({loading: true});
        http.post('/api/account/login/', formData)
          .then(data => {
            localStorage.setItem('token', data['access_token']);
            localStorage.setItem('nickname', data['nickname']);
            localStorage.setItem('is_supper', data['is_supper']);
            if (history.location.state && history.location.state['from']) {
              history.push(history.location.state['from'])
            } else if (data['is_supper']) {
              history.push('/home')
            } else {
              history.push('/bill/report')
            }
          }, () => this.setState({loading: false}))
      }
    })
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div className={styles.container}>
        <h1>锦亿百佳</h1>
        <div className={styles.formContainer}>
          <Form>
            <Form.Item className={styles.formItem}>
              {getFieldDecorator('username', {rules: [{required: true, message: '请输入账户'}]})(
                <Input
                size="large"
                autoComplete="off"
                placeholder="请输入账户"
                autoCapitalize="off"
                prefix={<Icon type="user" className={styles.icon}/>}/>
              )}
            </Form.Item>
            <Form.Item className={styles.formItem}>
              {getFieldDecorator('password', {rules: [{required: true, message: '请输入密码'}]})(
                <Input
                size="large"
                type="password"
                autoComplete="off"
                placeholder="请输入密码"
                prefix={<Icon type="lock" className={styles.icon}/>}/>
              )}
            </Form.Item>
            <Form.Item>
              <Button size="large" type="primary" loading={this.state.loading} onClick={this.handleSubmit}>登录</Button>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.footer}>
          Copyright <Icon type="copyright"/> 2019 By Insight Soft
        </div>
      </div>
    )
  }
}

export default Form.create()(LoginIndex)