import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'antd';
import GoodsForm from './Form';
import store from './store';

@observer
class StoreTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '门店名称',
    dataIndex: 'store_name'
  }, {
    title: '收银员',
    dataIndex: 'user_nickname',
  }, {
    title: '合计',
    dataIndex: 'total',
  }, {
    title: '误差',
    dataIndex: 'diff',
    render: value => {
      return <span style={{color: value > 0 ? 'green' : value === 0 ? null : 'red'}}>{value}</span>
    }
  }, {
    title: '日期',
    dataIndex: 'date'
  }, {
    title: '提交时间',
    dataIndex: 'created_at'
  }, {
    title: '提审时间',
    dataIndex: 'updated_at'
  }, {
    title: '备注',
    dataIndex: 'remarks'
  }];
// , {
//     title: '操作',
//     key: 'cz',
//     render: info => (
//       <span>
//         <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
//       </span>
//     )
//   }];

  render() {
    let data = store.records;
    if (store.f_status) {
      data = data.filter(x => x['status'] === store.f_status)
    }

    return (
      <React.Fragment>
        <Table loading={store.isFetching} rowKey="id" columns={this.columns} dataSource={data}/>
        {store.formVisible && <GoodsForm/>}
      </React.Fragment>
    )
  }
}

export default StoreTable