import React from 'react';
import { observer } from "mobx-react";
import { Button, Card, Select } from 'antd';
import { SearchForm } from '../../components';
import GoodsTable from './Table';
import store from './store';
import storeStore from '../store/store';

@observer
class GoodsIndex extends React.Component {
  componentDidMount() {
    if (storeStore.records.length === 0) {
      storeStore.fetchRecords()
    }
  }

  handleFetch = (v) => {
    store.store_id = v;
    store.fetchRecords()
  }

  render() {
    return (
      <Card>
        <SearchForm>
          <SearchForm.Item span={6} title="门店">
            <Select placeholder="请选择门店" value={store.store_id} onChange={this.handleFetch}>
              {storeStore.records.map(item => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </SearchForm.Item>
          <SearchForm.Item span={6}>
            <Button type="primary" icon="plus" onClick={e => store.showForm(e)}>新建检查区域</Button>
          </SearchForm.Item>
          <SearchForm.Item span={12} style={{textAlign: 'right'}}>
            <Button icon="sync" type="primary" onClick={() => store.fetchRecords()}>刷新</Button>
          </SearchForm.Item>
        </SearchForm>
        <GoodsTable/>
      </Card>
    )
  }
}

export default GoodsIndex