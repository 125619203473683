import {observable} from "mobx";
import http from '../../libs/http';
import moment from "moment";

class Store {
  @observable records = [];
  @observable record = {};
  @observable isFetching = false;
  @observable formVisible = false;

  @observable f_status;
  @observable f_name;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/goods/')
      .then(res => {
        res.map(item => {
          if (item['is_active']) {
            if (moment().isBefore(item['expired_at'])) {
              item['status'] = 'green'
            } else {
              item['status'] = 'orange'
            }
          } else if (item['inactive_at']) {
            item['status'] = 'red'
          } else {
            item['status'] = 'blue'
          }
          return true
        });
        this.records = res
      })
      .finally(() => this.isFetching = false)
  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info
  }
}

export default new Store()