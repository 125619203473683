import React from 'react';
import {observer} from "mobx-react";
import {Button, Card, Select, DatePicker, message} from 'antd';
import {SearchForm} from '../../components';
import StoreTable from './Table';
import storeStore from '../store/store';
import store from './store';
import lds from 'lodash';
import http from "../../libs/http";
import { exportToExcel } from "../../libs/functools";

@observer
class ComIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false
    }
  }
  componentDidMount() {
    if (lds.isEmpty(storeStore.records)) {
      storeStore.fetchRecords()
    }
  }

  handleChangeStore = (v) => {
    store.store_id = v;
    store.fetchRecords()
  };

  handleChangeDate = (v) => {
    store.date = v;
    store.fetchRecords()
  };

  handleExport = () => {
    if (store.range.length !== 2) {
      return message.error('请选到导出时间范围');
    }
    this.setState({isExporting: true});
    const [s, e] = store.range.map(x => x.format('YYYY-MM-DD'));
    http.get('/api/settle/', {responseType: 'blob', params: {s, e}})
      .then(res => {
        exportToExcel(res.data, `${s}-${e}对账单.xlsx`);
        this.setState({isExporting: false})
      })
      .finally(() => this.setState({isExporting: false}))
  };

  render() {
    return (
      <Card>
        <SearchForm>
          <SearchForm.Item span={4} title="门店">
            <Select allowClear placeholder="请选择" value={store.store_id} onChange={this.handleChangeStore}>
              {storeStore.records.map(item => (
                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </SearchForm.Item>
          <SearchForm.Item span={4} title="日期">
            <DatePicker allowClear={false} value={store.date} onChange={this.handleChangeDate} />
          </SearchForm.Item>
          <SearchForm.Item span={6} title="导出范围">
            <DatePicker.RangePicker onChange={v => store.range = v} />
          </SearchForm.Item>
          <SearchForm.Item span={4}>
            <Button icon="sync" type="primary" onClick={() => store.fetchRecords()}>刷新</Button>
          </SearchForm.Item>
          <SearchForm.Item span={4}>
            <Button icon="export" loading={this.state.isExporting} type="primary" onClick={this.handleExport}>导出</Button>
          </SearchForm.Item>
        </SearchForm>
        <StoreTable/>
      </Card>
    )
  }
}

export default ComIndex