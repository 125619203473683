import React from 'react';
import { observer } from 'mobx-react';
import { Table, Divider, Modal, message } from 'antd';
import { LinkButton } from '../../components';
import Article from './Article';
import ComForm from './Form';
import store from './store';
import http from "../../libs/http";

@observer
class GoodsTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '菜单名称',
    dataIndex: 'name',
  }, {
    title: '描述信息',
    dataIndex: 'desc',
    ellipsis: true
  }, {
    title: '操作',
    align: 'right',
    key: 'action',
    render: info => (
      <span>
        {info['is_main'] && (
          [<LinkButton disabled={info['has_article']} onClick={() => store.showSubForm(info)}>新建子菜单</LinkButton>,
          <Divider type="vertical"/>]
        )}

        <LinkButton disabled={info['children']} onClick={() => store.showArticle(info)}>编辑文章</LinkButton>
        <Divider type="vertical"/>
        <LinkButton onClick={() => store.showForm(info)}>编辑</LinkButton>
        <Divider type="vertical"/>
        <LinkButton onClick={() => this.handleDelete(info)}>删除</LinkButton>
      </span>
    )
  }];

  handleDelete = (info) => {
    Modal.confirm({
      title: '操作确认',
      content: `确定要要删除【${info.name}】？`,
      onOk: () => http.delete('/api/notify/', {params: {id: info.id}})
        .then(() => {
          message.success('操作成功');
          store.fetchRecords()
        })
    })
  };

  render() {
    let data = store.records;
    if (store.f_status) {
      data = data.filter(x => x['status'] === store.f_status)
    }

    return (
      <React.Fragment>
        <Table loading={store.isFetching} rowKey="id" columns={this.columns} dataSource={data}/>
        {store.formVisible && <ComForm/>}
        {store.articleVisible && <Article/>}
      </React.Fragment>
    )
  }
}

export default GoodsTable