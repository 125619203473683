// 前端页面的权限判断(仅作为前端功能展示的控制，具体权限控制应在后端实现)
export function hasPermission(strCode) {
  if (localStorage.getItem('is_supper') === 'true') return true;
  let permStr = localStorage.getItem('permissions');
  if (!strCode || !permStr) return false;
  const permissions = permStr.split(',');
  for (let or_item of strCode.split('|')) {
    if (isSubArray(permissions, or_item.split('&'))) {
      return true
    }
  }
  return false
}

//  数组包含关系判断
export function isSubArray(parent, child) {
  for (let item of child) {
    if (!parent.includes(item.trim())) {
      return false
    }
  }
  return true
}

// 转换数字至人民币大写
export function numToCny(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
  const head = n < 0 ? '欠' : '';
  n = Math.abs(n);

  let s = '';

  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);

  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
}

// 拼接oss的访问链接
export function signOSSLink(path, params = {}) {
  let paramsStr = '';
  for (let [key, value] of Object.entries(params)) {
    paramsStr += `&${key}=${encodeURIComponent(value)}`
  }
  return `/api/oss/${path}?x-token=${localStorage.getItem('token')}${paramsStr}`
}

// 用于替换toFixed方法，去除toFixed方法多余的0和小数点
export function trimFixed(data, bit) {
  return String(data.toFixed(bit)).replace(/0*$/, '').replace(/\.$/, '')
}

// 日期
export function human_date(date) {
  const now = date || new Date();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  return `${now.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
}

// 时间
export function human_time(date) {
  const now = date || new Date();
  const hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
  const minute = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
  const second = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
  return `${human_date()} ${hour}:${minute}:${second}`
}

// 导出excel
export function exportToExcel(data, filename) {
  const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  const evt = document.createEvent("MouseEvents");
  evt.initEvent("click", false, false);
  link.dispatchEvent(evt);
  document.body.removeChild(link);
}

// 解决js数字计算精度问题，有足够的精度，但非完全精准
export class Decimal {
  static toPrecision(num) {
    return Number(Number(num).toPrecision(12))
  }

  // 加
  static add(num, ...args) {
    let rst = Number(num);
    for (let arg of args) {
      rst += Number(arg)
    }
    return this.toPrecision(rst)
  }

  // 减
  static sub(num, ...args) {
    let rst = Number(num);
    for (let arg of args) {
      rst -= Number(arg)
    }
    return this.toPrecision(rst)
  }

  // 乘
  static mul(num, ...args) {
    let rst = Number(num);
    for (let arg of args) {
      rst *= Number(arg)
    }
    return this.toPrecision(rst)
  }

  // 除
  static div(num, ...args) {
    let rst = Number(num);
    for (let arg of args) {
      rst /= Number(arg)
    }
    return this.toPrecision(rst)
  }
}

// js对象和数组深拷贝
export function deepCopy(obj) {
  if (Array.isArray(obj)) {
    let result = [];
    for (let item of obj) {
      result.push(deepCopy(item))
    }
    return result
  } else if (typeof obj === 'object' && obj !== null) {
    let result = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = deepCopy(obj[key])
      }
    }
    return result
  } else {
    return obj
  }
}