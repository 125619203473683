import React from 'react';
import {Modal, Form, Input, message} from 'antd';
import http from '../../libs/http';
import store from './store';

class StoreForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData.id = store.record.id;
    http.post('/api/store/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={700}
        maskClosable={false}
        title={store.record.id ? '编辑门店' : '新建门店'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="门店名称">
            {getFieldDecorator('name', {initialValue: info['name']})(
              <Input placeholder="请输入门店名称"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="门店电话">
            {getFieldDecorator('telephone', {initialValue: info['telephone']})(
              <Input placeholder="请输入门店电话"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="门店地址">
            {getFieldDecorator('address', {initialValue: info['address']})(
              <Input placeholder="请输入门店地址"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="地理坐标">
            {getFieldDecorator('coordinate', {initialValue: info['coordinate']})(
              <Input placeholder="请输入门店地理坐标"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="营业时间">
            {getFieldDecorator('service_time', {initialValue: info['service_time']})(
              <Input placeholder="请输入门店营业时间"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(StoreForm)