import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, Result } from 'antd';
import http from '../../../libs/http';
import number from '../../../libs/number';
import lds from 'lodash';
import style from './i.module.css';

@observer
class ComIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      complete: false,
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    http.post('/api/bill/', formData)
      .then(() => this.setState({complete: true}))
      .finally(() => this.setState({loading: false}))
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({complete: false})
  };

  handleClose = () => {
    if (window.WeixinJSBridge) {
      window.WeixinJSBridge.call('closeWindow')
    } else {
      window.opener = null;
      window.open('', '_self', '');
      window.close()
    }
  };

  computeSum = () => {
    const fields = ['cash', 'e_payment', 'charge', 'settlement', 'reward', 'voucher', 'oneself'];
    const data = this.props.form.getFieldsValue(fields);
    const tmp = lds.sumBy(Object.values(data), x => Number(x) || 0);
    return number.plus(tmp, 0)
  };

  render() {
    const sum = this.computeSum();
    const computer = this.props.form.getFieldValue('computer');
    const {getFieldDecorator} = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 8},
        sm: {span: 8},
      },
      wrapperCol: {
        xs: {span: 16},
        sm: {span: 16},
      },
    };
    return (
      this.state.complete ? (
        <Result
          status="success"
          title="提交成功！"
          subTitle="每位收银员每天仅可提交一次"
          extra={[
            <Button type="primary" key="continue" onClick={this.handleReset}>继续</Button>,
            <Button type="default" key="close" onClick={this.handleClose}>关闭</Button>
          ]}/>
      ) : (
        <div className={style.container}>
          <Form {...formItemLayout}>
            <div className={style.item}>
              <div className={style.key}><span style={{color: "red"}}>* </span>现金：</div>
              <div className={style.value}>
                {getFieldDecorator('cash')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}><span style={{color: "red"}}>* </span>扫码：</div>
              <div className={style.value}>
                {getFieldDecorator('e_payment')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>刷卡：</div>
              <div className={style.value}>
                {getFieldDecorator('charge')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>结账：</div>
              <div className={style.value}>
                {getFieldDecorator('settlement')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>奖卡：</div>
              <div className={style.value}>
                {getFieldDecorator('reward')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>代金券：</div>
              <div className={style.value}>
                {getFieldDecorator('voucher')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>自用：</div>
              <div className={style.value}>
                {getFieldDecorator('oneself')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>合计：</div>
              <div className={style.value}>{sum}</div>
            </div>

            <div className={style.item}>
              <div className={style.key}><span style={{color: "red"}}>* </span>电脑：</div>
              <div className={style.value}>
                {getFieldDecorator('computer')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>误差：</div>
              <div className={style.value}>{computer ? number.minus(sum, computer) : null}</div>
            </div>
            <div className={style.item}>
              <div className={style.key}>入后：</div>
              <div className={style.value}>
                {getFieldDecorator('checkin')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>费用：</div>
              <div className={style.value}>
                {getFieldDecorator('cost')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>后台付款：</div>
              <div className={style.value}>
                {getFieldDecorator('backstage')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>总部结账：</div>
              <div className={style.value}>
                {getFieldDecorator('headquarters')(
                  <Input type="number" placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}><span style={{color: "red"}}>* </span>收银员：</div>
              <div className={style.value}>
                {getFieldDecorator('cashier')(
                  <Input placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}><span style={{color: "red"}}>* </span>日期：</div>
              <div className={style.value}>
                {getFieldDecorator('date')(
                  <Input type="date" placeholder="请选择"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>备注：</div>
              <div className={style.value}>
                {getFieldDecorator('remarks')(
                  <Input.TextArea placeholder="请输入"/>
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.key}>{null}</div>
              <div className={style.value}>
                <Button loading={this.state.loading} type="primary" onClick={this.handleSubmit}>提交</Button>
              </div>
            </div>
          </Form>
        </div>
      )
    )
  }
}

export default Form.create()(ComIndex)