import React from 'react';
import {observer} from 'mobx-react';
import {Table, Divider, Modal, message} from 'antd';
import UserForm from './Form';
import store from './store';
import http from '../../libs/http';

@observer
class UserTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '名称',
    dataIndex: 'name',
  }, {
    title: '面值',
    dataIndex: 'value',
  }, {
    title: '使用条件',
    dataIndex: 'condition',
  }, {
    title: '过期时间',
    dataIndex: 'expired_at',
  }, {
    title: '发放数量',
    dataIndex: 'total'
  }, {
    title: '使用数量',
    dataIndex: 'used'
  }, {
    title: '操作',
    key: 'cz',
    render: info => (
      <span>
        <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
        <Divider type="vertical"/>
        <span className="span-button" onClick={() => this.handleDelete(info)}>删除</span>
      </span>
    )
  }];

  handleDelete = (info) => {
    Modal.confirm({
      title: '操作确认',
      content: `确定要要删除【${info.name}】？`,
      onOk: () => http.delete('/api/coupon/', {params: {id: info.id}})
        .then(() => {
          message.success('操作成功');
          store.fetchRecords()
        })
    })
  };

  render() {
    let data = store.records;
    if (store.f_name) {
      data = data.filter(item => item['name'] && item['name'].toLowerCase().includes(store.f_name.toLowerCase()))
    }

    return (
      <React.Fragment>
        <Table loading={store.isFetching} rowKey="id" columns={this.columns} dataSource={data}/>
        {store.formVisible && <UserForm/>}
      </React.Fragment>
    )
  }
}

export default UserTable