import React from 'react';
import {observer} from 'mobx-react';
import {Table, Tag, Divider, Modal, message} from 'antd';
import GoodsForm from './Form';
import store from './store';
import http from '../../libs/http';

@observer
class StoreTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '门店名称',
    dataIndex: 'name'
  }, {
    title: '门店地址',
    dataIndex: 'address',
  }, {
    title: '营业时间',
    dataIndex: 'service_time',
  }, {
    title: '门店状态',
    dataIndex: 'is_active',
    render: value => value ? <Tag color="green">已启用</Tag> : <Tag color="red">已禁用</Tag>
  }, {
    title: '操作',
    key: 'cz',
    render: info => (
      <span>
        <span
          className="span-button"
          onClick={() => this.handleActive(info.id)}>
          {info['is_active'] ? '禁用' : '启用'}</span>
        <Divider type="vertical"/>
        <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
      </span>
    )
  }];

  handleActive = (id) => {
    Modal.confirm({
      title: '操作确认',
      content: '确定要执行该操作？',
      onOk: () => http.patch('/api/store/', {id})
        .then(() => {
          message.success('操作成功');
          store.fetchRecords()
        })
    })
  };

  render() {
    let data = store.records;
    if (store.f_status) {
      data = data.filter(x => x['status'] === store.f_status)
    }

    return (
      <React.Fragment>
        <Table loading={store.isFetching} rowKey="id" columns={this.columns} dataSource={data}/>
        {store.formVisible && <GoodsForm/>}
      </React.Fragment>
    )
  }
}

export default StoreTable