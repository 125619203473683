import {observable} from "mobx";
import http from '../../libs/http';

class Store {
  @observable records = [];
  @observable record = {};
  @observable subRecord = {};
  @observable store_id = undefined;
  @observable isFetching = false;
  @observable formVisible = false;
  @observable form2Visible = false;

  @observable f_status;

  fetchRecords = () => {
    this.isFetching = true;
    http.get('/api/inspect/', {params: {store_id: this.store_id}})
      .then(res => this.records = res)
      .finally(() => this.isFetching = false)
  };

  showForm = (e, info = {}) => {
    e.stopPropagation();
    this.formVisible = true;
    this.record = info
  };

  showSubForm = (e, info, subInfo) => {
    e.stopPropagation();
    this.record = info;
    this.subRecord = subInfo || {};
    this.form2Visible = true
  }
}

export default new Store()