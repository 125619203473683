import React from 'react';
import { observer } from 'mobx-react';
import { Modal, message, Form } from 'antd';
import { Upload } from '../../components';
import http from '../../libs/http';
import store from './store';


@observer
class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetching: true,
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData['id'] = store.record.id;
    http.patch('/api/notify/', formData)
      .then(() => {
        message.success('操作成功');
        store.articleVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    return (
      <Modal
        visible
        width={800}
        maskClosable={false}
        title={store.record.name}
        onCancel={() => store.articleVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form labelCol={{span: 6}} wrapperCol={{span: 14}}>
          <Form.Item required label="文章详情图">
            {getFieldDecorator('article', {initialValue: info['article']})(
              <Upload maxCount={10} path="bait/article"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(Article)