import React from 'react';
import {observer} from 'mobx-react';
import {Table, Tag, Divider, Modal, message} from 'antd';
import UserForm from './Form';
import store from './store';
import http from '../../libs/http';

@observer
class UserTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  columns = [{
    title: '用户头像',
    dataIndex: 'avatar',
    render: value => <img style={{width: 30, height: 30}} src={value} alt="avatar"/>
  }, {
    title: '用户昵称',
    key: 'nickname',
    render: info => (
      <span>
        {info['role'] !== '0' && <Tag color="blue">{info['role_alias']}</Tag>}
        {info['nickname']}
      </span>
    )
  }, {
    title: '用户状态',
    dataIndex: 'is_active',
    render: value => value ? <Tag color="green">已启用</Tag> : <Tag color="red">已禁用</Tag>
  }, {
    title: '注册时间',
    dataIndex: 'created_at',
    render: value => value.substr(0, 10)
  }, {
    title: '最近登录',
    dataIndex: 'last_login',
    render: value => value.substr(0, 10)
  }, {
    title: '操作',
    key: 'cz',
    render: info => (
      <span>
        <span
          className="span-button"
          onClick={() => this.handleActive(info.id)}>
          {info['is_active'] ? '禁用' : '启用'}</span>
        <Divider type="vertical"/>
        <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
      </span>
    )
  }];

  handleActive = (id) => {
    Modal.confirm({
      title: '操作确认',
      content: '确定要执行该操作？',
      onOk: () => http.patch('/api/wx/', {id, is_active: true})
        .then(() => {
          message.success('操作成功');
          store.fetchRecords()
        })
    })
  };

  render() {
    let data = store.records;
    if (store.f_name) {
      data = data.filter(item => item['nickname'] && item['nickname'].toLowerCase().includes(store.f_name.toLowerCase()))
    }
    if (store.f_active) {
      data = data.filter(x => x['is_active'] === (store.f_active === 'true'))
    }
    if (store.f_role) {
      data = data.filter(x => x['role'] === store.f_role)
    }

    return (
      <React.Fragment>
        <Table loading={store.isFetching} rowKey="id" columns={this.columns} dataSource={data}/>
        {store.formVisible && <UserForm/>}
      </React.Fragment>
    )
  }
}

export default UserTable