import React from 'react';
import { observer } from 'mobx-react';
import { Table, Divider, Modal, Icon, message } from 'antd';
import { LinkButton } from '../../components';
import ComForm from './Form';
import ComForm2 from './Form2';
import store from './store';
import http from "../../libs/http";

@observer
class GoodsTable extends React.Component {
  expandedRowRender = (record) => {
    const columns = [
      {title: '检查项', dataIndex: 'name'},
      {
        title: '排序', render: info => (
          <div>
            <Icon onClick={() => this.handleSubSort(record, info, 'up')} type="up-square"
                  style={{cursor: 'pointer', color: '#1890ff'}}/>
            <Divider type="vertical"/>
            <Icon onClick={() => this.handleSubSort(record, info, 'down')} type="down-square"
                  style={{cursor: 'pointer', color: '#1890ff'}}/>
          </div>
        )
      },
      {title: '分值', dataIndex: 'score'},
      {title: '更新时间', dataIndex: 'updated_at'},
      {
        title: '操作', key: 'action', render: info => (
          <span>
            <LinkButton onClick={e => store.showSubForm(e, record, info)}>编辑</LinkButton>
            <Divider type="vertical"/>
            <LinkButton onClick={e => this.handleSubDelete(e, record, info)}>删除</LinkButton>
          </span>
        )
      }
    ];

    return <Table columns={columns} dataSource={record.items} pagination={false}/>
  }

  columns = [{
    title: '检查区域',
    dataIndex: 'name',
  }, {
    title: '排序',
    key: 'sort',
    render: (info) => (
      <div>
        <Icon onClick={e => this.handleSort(e, info, 'up')} type="up-square"
              style={{cursor: 'pointer', color: '#1890ff'}}/>
        <Divider type="vertical"/>
        <Icon onClick={e => this.handleSort(e, info, 'down')} type="down-square"
              style={{cursor: 'pointer', color: '#1890ff'}}/>
      </div>
    ),
  }, {
    title: '照片限制',
    dataIndex: 'limit',
    render: value => `${value}张`
  }, {
    title: '检查项',
    render: info => `${info.items.length}项`
  }, {
    title: '操作',
    key: 'action',
    render: info => (
      <span>
        <LinkButton onClick={e => store.showSubForm(e, info)}>新建检查项</LinkButton>
        <Divider type="vertical"/>
        <LinkButton onClick={e => store.showForm(e, info)}>编辑</LinkButton>
        <Divider type="vertical"/>
        <LinkButton onClick={e => this.handleDelete(e, info)}>删除</LinkButton>
      </span>
    )
  }];

  handleSort = (e, info, sort) => {
    e.stopPropagation();
    store.fetching = true;
    http.patch(`/api/inspect/`, {id: info.id, sort})
      .then(store.fetchRecords, () => store.fetching = false)
  };

  handleSubSort = (record, info, sort) => {
    store.fetching = true;
    http.patch(`/api/inspect/${record.id}/`, {id: info.id, sort})
      .then(store.fetchRecords, () => store.fetching = false)
  };

  handleDelete = (e, info) => {
    e.stopPropagation();
    Modal.confirm({
      title: '操作确认',
      content: `确定要要删除【${info.name}】？`,
      onOk: () => http.delete('/api/inspect/', {params: {id: info.id}})
        .then(() => {
          message.success('操作成功');
          store.fetchRecords()
        })
    })
  };

  handleSubDelete = (e, record, info) => {
    e.stopPropagation();
    Modal.confirm({
      title: '操作确认',
      content: `确定要要删除【${info.name}】？`,
      onOk: () => http.delete(`/api/inspect/${record.id}/`, {params: {id: info.id}})
        .then(() => {
          message.success('操作成功');
          store.fetchRecords()
        })
    })
  };

  render() {
    let data = store.records;
    if (store.f_status) {
      data = data.filter(x => x['status'] === store.f_status)
    }

    return (
      <React.Fragment>
        <Table
          loading={store.isFetching}
          rowKey="id"
          columns={this.columns}
          dataSource={data}
          expandRowByClick={true}
          expandedRowRender={this.expandedRowRender}/>
        {store.formVisible && <ComForm/>}
        {store.form2Visible && <ComForm2/>}
      </React.Fragment>
    )
  }
}

export default GoodsTable