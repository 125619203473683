import React from 'react';
import { observer } from 'mobx-react';
import { Table, Tag, Divider, Modal, message } from 'antd';
import GoodsForm from './Form';
import store from './store';
import http from '../../libs/http';

@observer
class GoodsTable extends React.Component {
  componentDidMount() {
    store.fetchRecords()
  }

  colors_map = {
    red: '已下架',
    blue: '未上架',
    green: '进行中',
    orange: '已结束'
  };

  columns = [{
    title: '排序',
    dataIndex: 'order_id'
  }, {
    title: '商品信息',
    key: 'gp',
    render: info => (
      <span>
        <b>标题</b>: {info['title']}
        <br/>
        <b>规格</b>: {info['spec']}
      </span>
    )
  }, {
    title: '商品主图',
    dataIndex: 'thumb_url',
    render: value => <img style={{width: 40, height: 40}} src={value} alt="thumb"/>
  }, {
    title: '拼团信息',
    key: 'pt',
    render: info => (
      <span>
        <b>人数</b>: {info['scale']} 人团
        <br/>
        <b>价格</b>: ¥ {info['group_price']}
      </span>
    )
  }, {
    title: '销量信息',
    key: 'sales',
    render: info => (
      <span>
        <b>基数</b>: {info['sales']}
        <br/>
        <b>实际</b>: {info['sales_real']}
      </span>
    )
  }, {
    title: '活动信息',
    key: 'hd',
    render: info => (
      <span>
        <b>开始</b>: {info['active_at']}
        <br/>
        <b>结束</b>: {info['expired_at']}
      </span>
    )
  }, {
    title: '拼团状态',
    dataIndex: 'status',
    render: value => <Tag color={value}>{this.colors_map[value]}</Tag>
  }, {
    title: '操作',
    key: 'cz',
    render: info => (
      <span>
        <span
          className="span-button"
          onClick={() => this.handleActive(info.id)}>
          {info['is_active'] ? '下架' : '上架'}</span>
        <Divider type="vertical"/>
        <span className="span-button" onClick={() => store.showForm(info)}>编辑</span>
      </span>
    )
  }];

  handleActive = (id) => {
    Modal.confirm({
      title: '操作确认',
      content: '确定要执行该操作？',
      onOk: () => http.patch('/api/goods/', {id})
        .then(() => {
          message.success('操作成功');
          store.fetchRecords()
        })
    })
  };

  render() {
    let data = store.records;
    if (store.f_status) {
      data = data.filter(x => x['status'] === store.f_status)
    }
    if (store.f_name) {
      data = data.filter(x => x['title'].includes(store.f_name))
    }

    return (
      <React.Fragment>
        <Table loading={store.isFetching} rowKey="id" columns={this.columns} dataSource={data}/>
        {store.formVisible && <GoodsForm/>}
      </React.Fragment>
    )
  }
}

export default GoodsTable