import {observable} from "mobx";
import http from '../../../libs/http';
import moment from 'moment';

class Store {
  @observable records = [];
  @observable record = {};
  @observable isFetching = false;
  @observable formVisible = false;

  @observable store_id;
  @observable date = moment();
  @observable range = [];

  fetchRecords = () => {
    this.isFetching = true;
    const params = {store_id: this.store_id, date: this.date.format('YYYY-MM-DD')};
    http.get('/api/bill/', {params})
      .then(res => this.records = res)
      .finally(() => this.isFetching = false)
  };

  showForm = (info = {}) => {
    this.formVisible = true;
    this.record = info
  }
}

export default new Store()