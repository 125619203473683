import React from 'react';
import {observer} from "mobx-react";
import {Button, Card} from 'antd';
import {SearchForm} from '../../components';
import GoodsTable from './Table';
import store from './store';

@observer
class GoodsIndex extends React.Component {
  render() {
    return (
      <Card>
        <SearchForm>
          <SearchForm.Item span={6}>
            <Button type="primary" icon="plus" onClick={() => store.showForm()}>新建菜单</Button>
          </SearchForm.Item>
          <SearchForm.Item span={18} style={{textAlign: 'right'}}>
            <Button icon="sync" type="primary" onClick={() => store.fetchRecords()}>刷新</Button>
          </SearchForm.Item>
        </SearchForm>
        <GoodsTable/>
      </Card>
    )
  }
}

export default GoodsIndex