import React from 'react';
import { Layout } from 'antd';
import Sider from './Sider';
import Header from './Header';
import Footer from './Footer'
import { Router } from '../libs/router';


export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      collapsed: false
    }
  }

  render() {
    return (
      <Layout style={{minHeight: '100vh'}}>
        <Sider collapsed={this.state.collapsed}/>
        <Layout>
          <Header
            collapsed={this.state.collapsed}
            toggle={() => this.setState({collapsed: !this.state.collapsed})}
          />
          <Layout.Content style={{margin: '24px 24px 0', height: '100%'}}>
            <Router/>
          </Layout.Content>
          <Footer/>
        </Layout>
      </Layout>
    )
  }
}