import React from 'react';
import {Modal, Form, Input, DatePicker, message} from 'antd';
import http from '../../../libs/http';
import store from './store';
import moment from 'moment';

class StoreForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    this.setState({_: 1})
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData.id = store.record.id;
    if (formData['date']) {
      formData['date'] = formData['date'].format('YYYY-MM-DD')
    }
    http.post('/api/bill/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 14}
    };
    return (
      <Modal
        visible
        width={700}
        maskClosable={false}
        title={store.record.id ? '编辑账单' : '新建账单'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form>
          <Form.Item {...itemLayout} required label="现金">
            {getFieldDecorator('cash', {initialValue: info['cash']})(
              <Input type="number" placeholder="请输入"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="刷卡">
            {getFieldDecorator('charge', {initialValue: info['charge']})(
              <Input type="number" placeholder="请输入"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} required label="微信/支付宝">
            {getFieldDecorator('e_payment', {initialValue: info['e_payment']})(
              <Input type="number" placeholder="请输入"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="自用">
            {getFieldDecorator('oneself', {initialValue: info['oneself']})(
              <Input type="number" placeholder="请输入"/>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} label="兑奖">
            {getFieldDecorator('reward', {initialValue: info['reward']})(
              <Input type="number" placeholder="请输入"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="小程序返">
            {getFieldDecorator('voucher', {initialValue: info['voucher']})(
              <Input type="number" placeholder="请输入"/>
            )}
          </Form.Item>

          <Form.Item {...itemLayout} label="合计">
            {info.total}
          </Form.Item>
          <Form.Item {...itemLayout} required label="电脑">
            {getFieldDecorator('computer', {initialValue: info['computer']})(
              <Input type="number" placeholder="请输入"/>
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="误差">
            {info.diff}
          </Form.Item>
          <Form.Item {...itemLayout} required label="收银员">
            {info['user_nickname']}
          </Form.Item>
          <Form.Item {...itemLayout} required label="日期">
            {getFieldDecorator('date', {initialValue: info['date'] ? moment(info['date']) : undefined})(
              <DatePicker />
            )}
          </Form.Item>
          <Form.Item {...itemLayout} label="备注">
            {getFieldDecorator('remarks', {initialValue: info['remarks']})(
              <Input.TextArea placeholder="请输入"/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(StoreForm)