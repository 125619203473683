import React from 'react';
import {Modal, Form, Input, DatePicker, message} from 'antd';
import http from '../../libs/http';
import store from './store';
import moment from 'moment';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});
    const formData = this.props.form.getFieldsValue();
    formData.id = store.record.id;
    if (formData['expired_at']) {
      formData['expired_at'] = formData['expired_at'].format('YYYY-MM-DD')
    }
    http.post('/api/coupon/', formData)
      .then(() => {
        message.success('操作成功');
        store.formVisible = false;
        store.fetchRecords()
      }, () => this.setState({loading: false}))
  };

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    return (
      <Modal
        visible
        width={700}
        maskClosable={false}
        title={store.record.id ? '编辑优惠券' : '新建优惠券'}
        onCancel={() => store.formVisible = false}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}>
        <Form labelCol={{span: 6}} wrapperCol={{span: 14}}>
          <Form.Item required label="优惠券名称">
            {getFieldDecorator('name', {initialValue: info['name']})(
              <Input placeholder="请输入优惠券名称"/>
            )}
          </Form.Item>

          <Form.Item label="优惠券面值">
            {getFieldDecorator('value', {initialValue: info['value']})(
              <Input placeholder="请输入优惠券面值"/>
            )}
          </Form.Item>
          <Form.Item label="使用条件">
            {getFieldDecorator('condition', {initialValue: info['condition']})(
              <Input placeholder="请输入优惠券使用条件"/>
            )}
          </Form.Item>
          <Form.Item label="发放限制" help="该优惠券每用户每天最多发放数量">
            {getFieldDecorator('day_max', {initialValue: info['day_max']})(
              <Input placeholder="请输入优惠券每用户每天最多发放数量"/>
            )}
          </Form.Item>
          <Form.Item label="过期时间">
            {getFieldDecorator('expired_at', {initialValue: info['expired_at'] ? moment(info['expired_at']) : undefined})(
                <DatePicker placeholder="请输入" />
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(UserForm)